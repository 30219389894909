body, html {
  box-sizing: border-box;
  display: block;
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;
  touch-action: manipulation;
}

body *, body :after, body :before, html *, html :after, html :before {
  box-sizing:inherit;
}

/* Hide weird huge clear button on Internet Explorer */
body ::-ms-clear, html ::-ms-clear {
  display:none;
}

#root {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: stretch;
}
